<template>
  <div class="strategy-item-container bgf" @click="onDetail(false)">
    <div
      class="container-header flex flex-align-center ptb10 pl40 pr8 relative"
    >
      <div class="container-header-icon">
        <div
          class="title-icon-box flex flex-justify-center flex-align-center bgth"
        >
          <img class="title-icon" src="@/assets/img/quickSend/hint.svg" />
        </div>
      </div>
      <div class="wb cl29">
        <span class="fw500">{{ dataSource.strategy_name || '--' }}：</span>
        <span>{{ dataSource.action_describe || '--' }}</span>
      </div>
    </div>
    <div
      v-if="
        dataSource?.content.content ||
        (dataSource?.content.annex_content_json &&
          dataSource?.content.annex_content_json.length)
      "
      class="container-warp pl8 pr8 pt8"
    >
      <div v-if="dataSource.content.content" class="cl29 mb8 wb ws">
        {{ dataSource.content.content }}
      </div>
      <div v-if="dataSource?.content.annex_content_json && mediaList.length">
        <MediaGride
          pageType="strategy"
          mediaWidth="48px"
          mediaIconWidth="15px"
          :isShowMediaTitle="false"
          :isPreviewMedia="false"
          :mediaList="mediaList"
        />
      </div>
      <div class="media-handle flex flex-justify-between flex-align-center">
        <div
          v-if="String(dataSource.is_editable) === '1'"
          class="handle-box flex flex-align-center fs12 cl9c mr32"
          @click.stop="onDetail(true)"
        >
          <img class="icon mr4" src="@/assets/img/material-library/edit.svg" />
          编辑
        </div>
        <div v-else />
        <div
          class="handle-box flex-s0 flex flex-align-center fs12 cl9c fw500"
          @click.stop="onSend"
        >
          <img class="icon mr4" src="@/assets/img/material-library/send.svg" />
          发送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaGride from '@/components/detail/mediaGride.vue'
// import { navigateTo } from '@/utils/router'
import { sendMonitor, setBackSend } from '@/service/quickSend.service.js'
import {
  formatTaskItem,
  formatType,
  getFileCover,
  formatSendParams
} from '@/utils/sendMessage.js'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { Toast } from 'vant'
import { encodeParams } from '@/utils/router'
// import { getItem } from '@/utils/index.js'

export default {
  components: { MediaGride },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    qw_external_userid: {
      // 外部联系人userid (聊天工具栏才有)
      type: String,
      default: () => ''
    },
    qw_chat_id: {
      // 当前客户群的群ID (聊天工具栏才有)
      type: String,
      default: () => ''
    }
  },
  data() {
    return {}
  },
  computed: {
    mediaList() {
      return this.dataSource.content?.annex_content_json || []
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 前往内容编辑页面
    onDetail(isEdit) {
      const { mediaList, dataSource } = this
      const { content } = dataSource.content
      if (!content && !mediaList.length) return false

      this.$router.push({
        name: 'content-edit',
        query: encodeParams({
          pageType: 'strategy',
          strategy_id: dataSource.strategy_id,
          annex_content_json: mediaList,
          content,
          isEdit
        })
      })
    },
    // 聊天工具栏发送内容包
    async onSend() {
      try {
        const { dataSource, mediaList, qw_external_userid, qw_chat_id } = this
        const { strategy_id } = dataSource

        let status = false
        let contentArr = []
        if (mediaList.length) contentArr = [...formatTaskItem(mediaList)]
        if (dataSource.content && dataSource.content.content) {
          contentArr.unshift({
            type: 'TEXT',
            content: dataSource.content.content
          })
        }
        Toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        })

        const share_data_id = await this.beforeSend()
        for (let i = 0; i < contentArr.length; i++) {
          let type = formatType(contentArr[i].type)
          let params = await formatSendParams(
            type,
            {
              ...contentArr[i],
              strategy_id,
              customer_id: qw_external_userid,
              chart_id: qw_chat_id,
              source: 'strategy'
            },
            share_data_id
          )
          console.log('发送参数:::', type, params)
          const { sendStatus } = await sendChatMessageAllType(
            type,
            params,
            false
          )
          if (sendStatus) status = true
        }
        Toast.clear()
        if (status) {
          this.recordDynamics()
          this.sendSucceed()
        }
      } catch (error) {
        console.log(error)
        Toast.clear()
      }
    },
    // 发送前获取埋点id
    async beforeSend() {
      const { dataSource, qw_external_userid } = this
      const { strategy_id } = dataSource

      if (!qw_external_userid) return ''
      const body = {
        payable_id: strategy_id,
        type: 'strategy',
        user_id: qw_external_userid
      }

      let { data } = await sendMonitor(body)
      return data?.share_data_id || ''
    },
    // 记录发送动态
    async recordDynamics() {
      const { dataSource, qw_external_userid, qw_chat_id } = this
      const { strategy_id } = dataSource

      const body = {
        strategy_id,
        field_value: qw_external_userid || qw_chat_id || '',
        event_key: '',
        field: ''
      }
      if (qw_external_userid) {
        body.event_key = 'customer_receive_strategy'
        body.field = 'external_contact'
      } else if (qw_chat_id) {
        body.event_key = 'customer_touch_strategy_group'
        body.field = 'chat_id'
      }

      await setBackSend(body)
    },
    // 发送成功
    sendSucceed() {
      Toast.success('发送成功!')
      setTimeout(() => {
        this.$emit('onRefresh')
      }, 1500)
    }
  }
}
</script>

<style lang="less" scoped>
.icon {
  width: 16px;
  height: 16px;
}
.mb9 {
  margin-bottom: 9px;
}
.strategy-item-container {
  .container-header {
    border-radius: 2px 2px 0 0;
    background-color: #e3f0f0;

    .container-header-icon {
      position: absolute;
      left: 8px;
      top: 8px;
      bottom: 0;
    }
    .title-icon-box {
      width: 24px;
      height: 24px;
      border-radius: 50%;

      .title-icon {
        width: 9px;
        height: 13px;
      }
    }
  }
  .container-warp {
    border-radius: 0 0 2px 2px;
  }
  .media-handle {
    margin-top: -10px;

    .handle-box {
      height: 40px;
    }
  }
}
</style>
