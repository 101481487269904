<template>
  <div class="container fs14">
    <Skeleton v-show="pageLoading" />
    <!-- <div
      v-show="!pageLoading"
      class="container flex flex-direction-column relative"
    > -->
    <!-- <scroll-view
        class="scroll-container"
        scroll-y
        refresher-enabled
        :lower-threshold="150"
        :refresher-triggered="isTriggered"
        @refresherrefresh="refresherrefresh"
        @scrolltolower="getDataSource('load')"
      > -->
    <div
      v-show="!pageLoading"
      ref="scrollRef"
      class="overflow-y-scroll container"
      @scroll="onScroll"
    >
      <van-pull-refresh
        v-model="isTriggered"
        class="flex flex-direction-column relative"
        @refresh="onRefresh"
      >
        <!-- <van-list
        v-model="isLoading"
        class="scroll-container"
        :finished="isFinished"
        :immediate-check="false"
        @load="getDataSource('load')"
      > -->
        <div
          v-if="dataSource && dataSource.length"
          class="pl8 pr8"
          :style="{ 'min-height': contentHeight + 'px' }"
        >
          <div
            v-for="(item, index) in dataSource"
            :key="index"
            class="task-item mt12"
          >
            <StrategyItem
              :dataSource="item"
              :qw_external_userid="qw_external_userid"
              :qw_chat_id="qw_chat_id"
              @onRefresh="onRefresh"
            />
          </div>
        </div>
        <div
          v-else
          class="empty-data flex flex-direction-column flex-justify-center flex-align-center fs12 cl99 tc"
          :style="{ 'min-height': contentHeight + 'px' }"
        >
          <img
            src="@/assets/img/suggestion/task_nodata.svg"
            class="img block"
          />
          <!-- 暂无策略 -->
          暂无客户打法
        </div>
        <!-- </scroll-view> -->
        <!-- </div> -->
        <!-- </van-list> -->
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import StrategyItem from './strategyItem.vue'
import Skeleton from './skeleton.vue'
import { getStrategeList } from '@/service/quickSend.service.js'
import {
  getContext,
  getCurExternalContact,
  getCurExternalChat
} from '@/utils/nwechat.js'
// import { getItem } from '@/utils/index.js'

export default {
  components: { StrategyItem, Skeleton },
  data() {
    return {
      dataSource: [], // 策略列表
      qw_external_userid: '', // 外部联系人userid (聊天工具栏才有)
      qw_chat_id: '', // 当前客户群的群ID (聊天工具栏才有)
      isTriggered: false, // 列表上拉刷新
      scrollTop: 0, // 滑动距离
      contentHeight: 500, // 内容区域高度
      pageLoading: true // 骨架屏加载
    }
  },
  async mounted() {
    await this.getEntryType()
    await this.getDataSource('first', 'publish')
    if (this.pageLoading) this.pageLoading = false
    this.$nextTick(() => {
      this.contentHeight = this.$refs.scrollRef.offsetHeight
    })
  },
  methods: {
    activated() {
      if (this.isFinished) this.isFinished = false
      this.getDataSource('onShow')
      this.$nextTick(() => {
        this.$refs.scrollRef.scrollTop = this.scrollTop
        console.log('this.$refs.scrollRef.scrollTop', this.scrollTop)
      })
    },
    // 获取命中的策略列表 key 'first': 首次加载, 'onShow': 激活, 'load': 加载更多
    async getDataSource(key, type) {
      try {
        const { qw_external_userid, isTriggered } = this
        if (!qw_external_userid) {
          // if (this.isTriggered) this.isTriggered = false
          return (this.dataSource = [])
        }

        const { data, code } = await getStrategeList({
          external_userid: qw_external_userid
        })
        if (isTriggered) {
          setTimeout(() => (this.isTriggered = false), 300)
        }
        if (code == 200 && data) {
          this.dataSource = data
          if (!data.length && key === 'first' && type === 'publish') {
            this.$emit('tabChange')
          }
        }
      } catch (error) {
        // if (this.isTriggered) this.isTriggered = false
        console.log(error)
      }
    },
    // 获取页面入口类型
    async getEntryType() {
      //   this.qw_external_userid = 'wm1DICCQAAq9tj0yzhQu1uDVYbKoTnLg'
      const entry = await getContext()
      if (entry === 'single_chat_tools') {
        this.qw_external_userid = await getCurExternalContact()
        this.qw_chat_id = ''
      } else if (entry === 'group_chat_tools') {
        this.qw_external_userid = ''
        this.qw_chat_id = await getCurExternalChat()
      }
    },
    // 刷新列表
    onRefresh() {
      this.getDataSource('first')
    },
    // 监听滚动事件
    onScroll(event) {
      this.scrollTop = event.target.scrollTop
    }
    // refresherrefresh() {
    //   this.isTriggered = true
    //   this.onRefresh()
    // }
  }
}
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
.container {
  height: 100%;

  .scroll-container {
    height: 100%;
  }
  .list-bootom-hint {
    height: 44px;
    line-height: 44px;
  }
  .empty-data {
    width: 100%;
    height: 100%;

    .img {
      width: 200px;
      height: 132px;
    }
  }
}
</style>
