<template name="skeleton">
  <div
    class="container task-component--container flex task-component--flex flex-direction-column task-component--flex-direction-column relative task-component--relative data-v-69becaf9 task-component--data-v-69becaf9"
  >
    <div
      scroll-y="true"
      refresher-enabled="true"
      class="scroll-container task-component--scroll-container data-v-69becaf9 task-component--data-v-69becaf9"
      lower-threshold="150"
    >
      <div
        class="plr15 task-component--plr15 data-v-69becaf9 task-component--data-v-69becaf9"
      >
        <div
          class="task-item task-component--task-item mt12 task-component--mt12 data-v-69becaf9 task-component--data-v-69becaf9"
        >
          <div class="data-v-69becaf9 task-component--data-v-69becaf9">
            <div
              class="task-item-container taskItem--task-item-container bgf taskItem--bgf bdr4 taskItem--bdr4 pt12 taskItem--pt12 plr15 taskItem--plr15 data-v-312c226c taskItem--data-v-312c226c"
            >
              <div
                class="fs16 taskItem--fs16 fw500 taskItem--fw500 mb9 taskItem--mb9 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-41 sk-text"
              >
                厚度防护是符合今年君
              </div>
              <div
                class="fs12 taskItem--fs12 cl9c taskItem--cl9c flex taskItem--flex flex-align-center taskItem--flex-align-center mb8 taskItem--mb8 data-v-312c226c taskItem--data-v-312c226c"
              >
                <span
                  class="mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-34 sk-text"
                >
                  距离任务结束剩余
                </span>
                <span
                  class="clth taskItem--clth mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  3
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  天
                </span>
                <span
                  class="clth taskItem--clth ml4 taskItem--ml4 mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-431 sk-text"
                >
                  06
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  时
                </span>
                <span
                  class="clth taskItem--clth ml4 taskItem--ml4 mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-454 sk-text"
                >
                  35
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  分
                </span>
              </div>
              <div
                class="wb taskItem--wb mb8 taskItem--mb8 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-897 sk-text"
              >
                发计算机房i随机附件符合撒u发挥双方滑石粉滑石粉滑石粉话舒服啊舒服啊回复啊回复啊回复啊护发火速发货素防护户符合
              </div>
              <div class="data-v-312c226c taskItem--data-v-312c226c">
                <div class="data-v-312c226c taskItem--data-v-312c226c">
                  <div
                    class="detail-media mediaGride--detail-media data-v-076746a4 mediaGride--data-v-076746a4"
                  >
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <image
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <div
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          object-fit="fill"
                          src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/f18621481ef28107b1819f7010f6d1ff.mp4"
                          unit-id="true"
                        ></div>
                        <image
                          class="play-icon mediaGride--play-icon data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          style="width: 15px; height: 15px"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <image
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <div
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          object-fit="fill"
                          src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/f18621481ef28107b1819f7010f6d1ff.mp4"
                          unit-id="true"
                        ></div>
                        <image
                          class="play-icon mediaGride--play-icon data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          style="width: 15px; height: 15px"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="r mediaGride--r data-v-076746a4 mediaGride--data-v-076746a4"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="media-handle taskItem--media-handle flex taskItem--flex flex-justify-end taskItem--flex-justify-end flex-align-center taskItem--flex-align-center data-v-312c226c taskItem--data-v-312c226c"
              >
                <div
                  class="handle-box taskItem--handle-box flex taskItem--flex flex-align-center taskItem--flex-align-center fs12 taskItem--fs12 cl9c taskItem--cl9c mr65 taskItem--mr65 data-v-312c226c taskItem--data-v-312c226c sk-transparent"
                >
                  <image
                    class="icon taskItem--icon mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-image"
                  ></image>
                  编辑
                </div>
                <div
                  class="handle-box taskItem--handle-box flex-s0 taskItem--flex-s0 flex taskItem--flex flex-align-center taskItem--flex-align-center fs12 taskItem--fs12 cl9c taskItem--cl9c fw500 taskItem--fw500 data-v-312c226c taskItem--data-v-312c226c sk-transparent"
                >
                  <image
                    class="icon taskItem--icon mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-image"
                  ></image>
                  发送
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="task-item task-component--task-item mt12 task-component--mt12 data-v-69becaf9 task-component--data-v-69becaf9"
        >
          <div class="data-v-69becaf9 task-component--data-v-69becaf9">
            <div
              class="task-item-container taskItem--task-item-container bgf taskItem--bgf bdr4 taskItem--bdr4 pt12 taskItem--pt12 plr15 taskItem--plr15 data-v-312c226c taskItem--data-v-312c226c"
            >
              <div
                class="fs16 taskItem--fs16 fw500 taskItem--fw500 mb9 taskItem--mb9 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-41 sk-text"
              >
                厚度防护是符合今年君
              </div>
              <div
                class="fs12 taskItem--fs12 cl9c taskItem--cl9c flex taskItem--flex flex-align-center taskItem--flex-align-center mb8 taskItem--mb8 data-v-312c226c taskItem--data-v-312c226c"
              >
                <span
                  class="mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-467 sk-text"
                >
                  距离任务结束剩余
                </span>
                <span
                  class="clth taskItem--clth mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  3
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  天
                </span>
                <span
                  class="clth taskItem--clth ml4 taskItem--ml4 mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-610 sk-text"
                >
                  06
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  时
                </span>
                <span
                  class="clth taskItem--clth ml4 taskItem--ml4 mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-38 sk-text"
                >
                  35
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  分
                </span>
              </div>
              <div
                class="wb taskItem--wb mb8 taskItem--mb8 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-868 sk-text"
              >
                发计算机房i随机附件符合撒u发挥双方滑石粉滑石粉滑石粉话舒服啊舒服啊回复啊回复啊回复啊护发火速发货素防护户符合
              </div>
              <div class="data-v-312c226c taskItem--data-v-312c226c">
                <div class="data-v-312c226c taskItem--data-v-312c226c">
                  <div
                    class="detail-media mediaGride--detail-media data-v-076746a4 mediaGride--data-v-076746a4"
                  >
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <image
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <div
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          object-fit="fill"
                          src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/f18621481ef28107b1819f7010f6d1ff.mp4"
                          unit-id="true"
                        ></div>
                        <image
                          class="play-icon mediaGride--play-icon data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          style="width: 15px; height: 15px"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <image
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="detail-media-item mediaGride--detail-media-item data-v-076746a4 mediaGride--data-v-076746a4"
                      style="width: 48px"
                    >
                      <div
                        class="detail-media-box mediaGride--detail-media-box data-v-076746a4 mediaGride--data-v-076746a4"
                      >
                        <div
                          class="cover-img mediaGride--cover-img data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          object-fit="fill"
                          src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/f18621481ef28107b1819f7010f6d1ff.mp4"
                          unit-id="true"
                        ></div>
                        <image
                          class="play-icon mediaGride--play-icon data-v-076746a4 mediaGride--data-v-076746a4 sk-image"
                          style="width: 15px; height: 15px"
                        ></image>
                      </div>
                    </div>
                    <div
                      class="r mediaGride--r data-v-076746a4 mediaGride--data-v-076746a4"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="media-handle taskItem--media-handle flex taskItem--flex flex-justify-end taskItem--flex-justify-end flex-align-center taskItem--flex-align-center data-v-312c226c taskItem--data-v-312c226c"
              >
                <div
                  class="handle-box taskItem--handle-box flex taskItem--flex flex-align-center taskItem--flex-align-center fs12 taskItem--fs12 cl9c taskItem--cl9c mr65 taskItem--mr65 data-v-312c226c taskItem--data-v-312c226c sk-transparent"
                >
                  <image
                    class="icon taskItem--icon mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-image"
                  ></image>
                  编辑
                </div>
                <div
                  class="handle-box taskItem--handle-box flex-s0 taskItem--flex-s0 flex taskItem--flex flex-align-center taskItem--flex-align-center fs12 taskItem--fs12 cl9c taskItem--cl9c fw500 taskItem--fw500 data-v-312c226c taskItem--data-v-312c226c sk-transparent"
                >
                  <image
                    class="icon taskItem--icon mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-image"
                  ></image>
                  发送
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="task-item task-component--task-item mt12 task-component--mt12 data-v-69becaf9 task-component--data-v-69becaf9"
        >
          <div class="data-v-69becaf9 task-component--data-v-69becaf9">
            <div
              class="task-item-container taskItem--task-item-container bgf taskItem--bgf bdr4 taskItem--bdr4 pt12 taskItem--pt12 plr15 taskItem--plr15 data-v-312c226c taskItem--data-v-312c226c"
            >
              <div
                class="fs16 taskItem--fs16 fw500 taskItem--fw500 mb9 taskItem--mb9 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-894 sk-text"
              >
                厚度防护是符合今年君
              </div>
              <div
                class="fs12 taskItem--fs12 cl9c taskItem--cl9c flex taskItem--flex flex-align-center taskItem--flex-align-center mb8 taskItem--mb8 data-v-312c226c taskItem--data-v-312c226c"
              >
                <span
                  class="mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-867 sk-text"
                >
                  距离任务结束剩余
                </span>
                <span
                  class="clth taskItem--clth mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  3
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  天
                </span>
                <span
                  class="clth taskItem--clth ml4 taskItem--ml4 mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-444 sk-text"
                >
                  06
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  时
                </span>
                <span
                  class="clth taskItem--clth ml4 taskItem--ml4 mr4 taskItem--mr4 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-216 sk-text"
                >
                  35
                </span>
                <span
                  class="data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-opacity"
                >
                  分
                </span>
              </div>
              <div
                class="wb taskItem--wb mb8 taskItem--mb8 data-v-312c226c taskItem--data-v-312c226c sk-transparent sk-text-14-2857-416 sk-text"
              >
                发计算机房i随机附件符合撒u发挥双方滑石粉滑石粉滑石粉话舒服啊舒服啊回复啊回复啊回复啊护发火速发货素防护户符合
              </div>
              <div
                class="media-handle taskItem--media-handle flex taskItem--flex flex-justify-end taskItem--flex-justify-end flex-align-center taskItem--flex-align-center data-v-312c226c taskItem--data-v-312c226c"
              >
                <div
                  class="handle-box taskItem--handle-box flex taskItem--flex flex-align-center taskItem--flex-align-center fs12 taskItem--fs12 cl9c taskItem--cl9c mr65 taskItem--mr65 data-v-312c226c taskItem--data-v-312c226c sk-transparent"
                >
                  编辑
                </div>
                <div
                  class="handle-box taskItem--handle-box flex-s0 taskItem--flex-s0 flex taskItem--flex flex-align-center taskItem--flex-align-center fs12 taskItem--fs12 cl9c taskItem--cl9c fw500 taskItem--fw500 data-v-312c226c taskItem--data-v-312c226c sk-transparent"
                >
                  发送
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style lang="less">
.container {
  height: 100%;

  .scroll-container {
    height: 100%;
  }
  .list-bootom-hint {
    height: 44px;
    line-height: 44px;
  }
  .empty-data {
    width: 100%;
    height: 100%;

    .img {
      width: 200px;
      height: 132px;
    }
  }
}
.icon {
  width: 16px;
  height: 16px;
}
.mb9 {
  margin-bottom: 9px;
}
.strategy-item-container {
  .container-header {
    border-radius: 2px 2px 0 0;
    background-color: #e3f0f0;

    .container-header-icon {
      position: absolute;
      left: 8px;
      top: 8px;
      bottom: 0;
    }
    .title-icon-box {
      width: 24px;
      height: 24px;
      border-radius: 50%;

      .title-icon {
        width: 9px;
        height: 13px;
      }
    }
  }
  .container-warp {
    border-radius: 0 0 2px 2px;
  }
  .media-handle {
    margin-top: -10px;

    .handle-box {
      height: 40px;
    }
  }
}
.sk-transparent {
  color: transparent !important;
}
.sk-text-14-2857-41 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 44.8rpx;
  position: relative !important;
}
.sk-text {
  background-origin: content-box !important;
  background-clip: content-box !important;
  background-color: transparent !important;
  color: transparent !important;
  background-repeat: repeat-y !important;
}
.sk-text-14-2857-34 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-opacity {
  opacity: 0 !important;
}
.sk-text-14-2857-431 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-454 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-897 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-2857-467 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-610 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-38 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-868 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-2857-894 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 44.8rpx;
  position: relative !important;
}
.sk-text-14-2857-867 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-444 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-216 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 33.6rpx;
  position: relative !important;
}
.sk-text-14-2857-416 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-7059-18 {
  background-image: linear-gradient(
    transparent 14.7059%,
    #eeeeee 0%,
    #eeeeee 85.2941%,
    transparent 0%
  ) !important;
  background-size: 100% 34rpx;
  position: relative !important;
}
.sk-text-14-7059-411 {
  background-image: linear-gradient(
    transparent 14.7059%,
    #eeeeee 0%,
    #eeeeee 85.2941%,
    transparent 0%
  ) !important;
  background-size: 100% 34rpx;
  position: relative !important;
}
.sk-image {
  background: #efefef !important;
}
.sk-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
}
</style>
